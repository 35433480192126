<template>
<div>
  <!------------Business Category 1 Start -------------------->
  <router-link v-if = "businessCategoryLayout == '1'" :to="{name:'category', params:{category_id: b64_to_utf8(item.category_id+'/'+item.category_name)}}">
    <div v-if="!right" class="resturant-item busines-img-box d-flex align-items-start" :style="{ background: 'url(' + item.category_img + ')' }">
      <div class="busines-img">
        <div class="resturant-data ms-3">
            <h4>{{item.category_name}}</h4>
            <span>{{item.category_desc}}</span>
        </div>
      </div>
    </div>
  </router-link>
  <!------------Business Category 1 End -------------------->
  <!------------Business Category 2 Start -------------------->
  <router-link v-if="businessCategoryLayout == '2'" :to="{name:'category', params:{category_id: b64_to_utf8(item.category_id+'/'+item.category_name)}}">
    <div v-if="!right" class="resturant-item busines-img-box" :style="{ background: item.color_code+'!important' }">
      <div class="busines-img">
        <div class="img-box">
          <img :src="item.category_img">  
        </div>
        <div class="resturant-data ms-3">
            <h4 :style="{ color: item.text_color_code+'!important' }">{{item.category_name}}</h4>
        </div>
      </div>
    </div>
  </router-link>
  <!------------Business Category 2 End -------------------->
  <!------------Business Category 3 Start -------------------->
  <router-link v-if = "businessCategoryLayout == '3'" :to="{name:'category', params:{category_id: b64_to_utf8(item.category_id+'/'+item.category_name)}}">
    <div v-if="!right" class="resturant-item busines-img-box">
      <div class="busines-img">
        <div class="img-box">
          <img :src="item.category_img">          
        </div>
        <div class="resturant-data ms-3">
            <h4 :style="{ color: item.text_color_code+'!important' }">{{item.category_name}}</h4>
        </div>
      </div>
    </div>
  </router-link>
  <!------------Business Category 3 End -------------------->
  <!------------Business Category 4 Start -------------------->
  
  <router-link v-if = "businessCategoryLayout == '4'" :to="{name:'category', params:{category_id: b64_to_utf8(item.category_id+'/'+item.category_name)}}">
    <div v-if="!right" class="resturant-item busines-img-box" :style="{ background: item.color_code+'!important' }">
      <div class="busines-img">
        <div class="resturant-data ms-3">
            <h4 :style="{ color: item.text_color_code+'!important' }">{{item.category_name}}</h4>
        </div>
        <div class="img-box">
          <img :src="item.category_img">          
        </div>
      </div>
    </div>
  </router-link>
  <!------------Business Category 4 End -------------------->
  <!------------Business Category 5 Start -------------------->
  <router-link v-if = "businessCategoryLayout == '5'" :to="{name:'category', params:{category_id: b64_to_utf8(item.category_id+'/'+item.category_name)}}">
    <div v-if="!right" class="resturant-item busines-img-box">
      <div class="busines-img">
        <div class="img-box">
          <img :src="item.category_img">          
        </div>
        <div class="resturant-data ms-3">
            <h4 :style="{ color: item.text_color_code+'!important' }">{{item.category_name}}</h4>
        </div>
      </div>
    </div>
  </router-link>
  <!------------Business Category 5 End -------------------->
  <!------------Business Category 6 Start -------------------->
  <router-link v-if = "businessCategoryLayout == '6'" :to="{name:'category', params:{category_id: b64_to_utf8(item.category_id+'/'+item.category_name)}}">
    <div v-if="!right" class="resturant-item busines-img-box" :style="{ background: item.color_code+'!important' }">
      <div class="busines-img">
        <div class="resturant-data ms-3">
            <h4 :style="{ color: item.text_color_code+'!important' }">{{item.category_name}}</h4>
            <span>{{item.category_desc}}</span>
        </div>
        <div class="img-box">
          <img :src="item.category_img">          
        </div>
      </div>
    </div>
  </router-link>
  <!------------Business Category 6 End -------------------->
</div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  components: {
    'skeleton-loader-vue':VueSkeletonLoader
  },
  props: ['item','right','businessCategoryLayout'],
  
  methods: {
   b64_to_utf8(str) {
    var b64 = window.btoa(unescape(encodeURIComponent(str)))
    return b64;
  }    
}
};
</script>